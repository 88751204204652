import PricingCard from "../components/PricingCard";

export default function ChoosePlan(){
    return <>
     {/* Pricing Section */}
     <section className="pt-32 pb-20 relative overflow-hidden" id="pricing">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-5xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent mb-6">
              Simple, Transparent Pricing
            </h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
              No commissions. No hidden fees. Just valuable tools designed to help you grow your influence and income.
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
            <PricingCard
              plan="Monthly"
              price="5"
              url="https://buy.stripe.com/dR66qYc4r9pF288dQT"
              features={[
                "AI-powered campaign matching",
                "Comprehensive analytics dashboard",
                "Unlimited active campaigns",
                "0% commission on earnings",
                "Full feature access",
                "Priority support"
              ]}
            />
            <PricingCard
              plan="Annual"
              price="50"
              originalPrice="60"
              url="https://buy.stripe.com/3cs7v23xVgS7144aEE"
              features={[
                "Everything in Monthly plan",
                "2 months free",
                "Early access to new features",
                "Advanced analytics",
                "Priority campaign matching",
                "Dedicated support team"
              ]}
              isPopular={true}
            />
          </div>

          <div className="mt-12 text-center">
            <p className="text-gray-400">
              * Annual plan saves you $10/year compared to monthly billing
            </p>
          </div>
        </div>
      </section>
    </>
}