import { Star } from "lucide-react";

const PricingCard = ({ plan, price, originalPrice, url, features, isPopular }: any) => (
    <div className={`relative bg-white/5 backdrop-blur-sm rounded-2xl p-8 border ${isPopular ? 'border-pink-500' : 'border-white/10'} hover:border-pink-500/50 transition-all duration-300`}>
      {isPopular && (
        <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 bg-gradient-to-r from-pink-500 to-purple-600 text-white px-4 py-1 rounded-full text-sm">
          Most Popular
        </div>
      )}
      <h3 className="text-2xl font-bold text-white mb-2">{plan}</h3>
      <div className="mb-6">
        <span className="text-4xl font-bold text-white">${price}</span>
        {originalPrice && <span className="text-gray-400 line-through ml-2">${originalPrice}</span>}
        <span className="text-gray-400">/month</span>
      </div>
      <ul className="space-y-4 mb-8">
        {features.map((feature: any, index: any) => (
          <li key={index} className="flex items-center text-gray-300">
            <Star className="w-5 h-5 text-pink-500 mr-2 flex-shrink-0" />
            {feature}
          </li>
        ))}
      </ul>
      <button onClick={() => window.location.href = url} className={`w-full py-3 ${isPopular ? 'bg-gradient-to-r from-pink-500 to-purple-600' : 'bg-white/10'} rounded-xl text-white font-medium hover:shadow-lg hover:shadow-pink-500/25 transition-all duration-300`}>
        Get Started
      </button>
    </div>
  );

  export default PricingCard