import axios from "axios";
import { ArrowRight, Facebook, Instagram, Twitter, TypeOutline, Youtube } from "lucide-react";
import Swal from "sweetalert2";

export default function SocialPool() {


  const submitSocial = async (event:any) => {

    event.preventDefault();
    const data:any = {};

    data['categories'] = event.target.elements.categories.value;
    data['engagementRate'] = event.target.elements.engagement_rate.value;
    data['audienceSize'] = event.target.elements.audience_size.value;
    data['interests'] = event.target.elements.interests.value;
    data['availableForCampaigns'] = event.target.elements.available_for_campaign.checked;
    
    try{
      const token = localStorage.getItem("token");
      const res:any = await axios.post("/api/audience-pool", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(res);

      Swal.fire("Audience pool updated successfully!", "", "success").then(() => {
        window.location.href = '/dashboard'; 
      });
      
    }catch(err){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An error occurred while processing your request.",
      });
      console.log(err);
    }

  }

  return <>

    {/* Hero Section */}
    <section className="pt-32 pb-20 relative overflow-hidden">
      <div className="absolute top-1/4 right-0 w-96 h-96 bg-purple-500/10 rounded-full blur-3xl"></div>
      <div className="absolute bottom-1/4 left-0 w-96 h-96 bg-pink-500/10 rounded-full blur-3xl"></div>

      <div className="max-w-7xl mx-auto px-4">
        <div className="grid md:grid-cols-1 gap-12 items-center text-center">


          <h1 className="text-4xl md:text-6xl font-bold space-y-2">
            <span className="bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent block">
              Unlock Your
            </span>
            <span className="bg-gradient-to-r from-pink-500 to-purple-500 bg-clip-text text-transparent block">
              Social Media Potential
            </span>
          </h1>

          <p className="text-xl text-gray-300">
            You're passionate about creating content that resonates with others. It's time to transform that passion into a thriving career.
          </p>

          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-6 justify-center">
            <button className="px-8 py-4 bg-gradient-to-r from-pink-500 to-purple-500 rounded-xl text-white font-medium hover:shadow-lg hover:shadow-pink-500/25 transition-all duration-300 flex items-center justify-center space-x-2">
              <span>Get Started Now</span>
              <ArrowRight className="w-5 h-5" />
            </button>
            <button className="px-8 py-4 bg-white/10 rounded-xl text-white font-medium hover:bg-white/20 transition-all duration-300">
              Learn More
            </button>
          </div>


        </div>
      </div>
    </section>

    <section className="pt-32 pb-20 relative overflow-hidden">
      <div className="py-8 lg:py-16 px-8 bg-white/5 backdrop-blur-sm rounded-md">
        <div className="container mx-auto">
          <h2 className="text-center text-3xl md:text-5xl font-bold text-white mb-6">Ready to Maximize Your Influence?</h2>
          {/* <p className="text-gray-400 text-center">
            Have questions or eager to revolutionize your social media monetization? We're here to guide you!
          </p> */}
         
          <form onSubmit={submitSocial} action="#" className="space-y-8">
            
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="categories" className="block text-sm font-medium text-gray-300">Categories (comma-separated)</label>
                <input type="text" id="categories" name="categories" className="w-full leading-none text-gray-50 p-3 focus:outline-none focus:border-blue-700 mt-4 border-0 bg-gray-800 rounded" required />
              </div>
              <div>
                <label htmlFor="audience_size" className="block text-sm font-medium text-gray-300">Audience Size</label>
                <input type="text" id="audience_size" name="audience_size" className="w-full leading-none text-gray-50 p-3 focus:outline-none focus:border-blue-700 mt-4 border-0 bg-gray-800 rounded" required />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="engagement_rate" className="block text-sm font-medium text-gray-300">Engagement Rate (%)</label>
                <input type="number" id="engagement_rate" name="engagement_rate" className="w-full leading-none text-gray-50 p-3 focus:outline-none focus:border-blue-700 mt-4 border-0 bg-gray-800 rounded" required />
              </div>
              <div>
                <label htmlFor="interests" className="block text-sm font-medium text-gray-300">Interests (comma-separated)</label>
                <input type="text" id="interests" name="interests" className="w-full leading-none text-gray-50 p-3 focus:outline-none focus:border-blue-700 mt-4 border-0 bg-gray-800 rounded" required />
              </div>
            </div>

            <div className="flex items-center mb-4">
                <input id="default-checkbox" required type="checkbox" name="available_for_campaign" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="default-checkbox" className="ms-2 text-sm font-medium text-gray-300">Available for Campaigns</label>
            </div>

            <button className="px-8 py-4 bg-gradient-to-r from-pink-500 to-purple-500 rounded-xl text-white font-medium hover:shadow-lg hover:shadow-pink-500/25 transition-all duration-300 flex items-center justify-center space-x-2">
              <span>Submit</span>
              <ArrowRight className="w-5 h-5" />
            </button>
          </form>
        </div>

      </div>
    </section>

  </>

}