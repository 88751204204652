import { useEffect, useState } from 'react';
import { ArrowRight, Users, Target, Mail, Phone, MapPin, Star, ChevronRight, Facebook, Youtube, Instagram, Twitter, TypeOutline } from 'lucide-react';
import { Outlet, Link } from "react-router-dom";


export default function MainLayout() {


  useEffect(() => {
    // alert(window.location.pathname)
  }, []);

    const platforms = [
      {
        name: "Facebook",
        icon: <Facebook className="w-5 h-5 text-gray-400"/>,
        link: "https://www.facebook.com/profile.php?id=61566935737233"
      },
      {
        name: "Twitter",
        icon: <Twitter className="w-5 h-5 text-gray-400"/>,
        link: "https://x.com/The_Adpool"
      },
      {
        name: "Instagram",
        icon: <Instagram className="w-5 h-5 text-gray-400"/>,
        link: "https://www.instagram.com/the.adpool"
      },
      {
        name: "Youtube",
        icon: <Youtube className="w-5 h-5 text-gray-400"/>,
        link: "https://www.youtube.com/@TheAdPool"
      },
      {
        name: "Tiktok",
        icon: <TypeOutline className="w-5 h-5 text-gray-400"/>,
        link: "https://www.tiktok.com/@theadpool"
      },
    ];


    const getFooterMenu = (item:any,i:number) => {

      if(i === 3){
        return <a href={`/terms`}  className="text-gray-400 hover:text-white transition-colors">
          {item}
        </a>
      }else if(i === 4){
        return <a href={`/affiliate`}  className="text-gray-400 hover:text-white transition-colors">
          {item}
        </a>
      }
      
      let href = "";
      if(window.location.pathname !== "/"){
        href += "/#";
      }else{
        href += "#";
      }
      href += item.split(" ").join("-").toLowerCase();

      return <a href={`${href}`}  className="text-gray-400 hover:text-white transition-colors">
          {item}
      </a>

    }

    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-900 via-black to-gray-900">
       <nav className="fixed top-0 w-full backdrop-blur-md bg-white/10 z-50 border-b border-white/10">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between items-center h-20">
          <div className="flex items-center space-x-2">
            <a href='/' className="text-2xl font-bold bg-gradient-to-r from-pink-500 to-purple-600 bg-clip-text text-transparent">
              TheAdPool
            </a>
          </div>
          
          <div className="hidden md:flex space-x-8">
            {['Features', 'How It Works', 'Testimonials', 'Pricing', 'Contact'].map((item) => (
              <a key={item} href={`#${item.replace(/\s+/g, '-').toLowerCase()}`} className="text-white/80 hover:text-white transition-colors duration-200">
                {item}
              </a>
            ))}
          </div>
  
          <div className="flex items-center space-x-4">
            <Link to="/login" className="px-4 py-2 text-white/80 hover:text-white transition-colors">
              Login
            </Link>
            <Link to="/register" className="px-6 py-2 bg-gradient-to-r from-pink-500 to-purple-600 rounded-full text-white hover:shadow-lg hover:shadow-pink-500/25 transition-all duration-200">
              Register
            </Link>
          </div>
        </div>
      </div>
    </nav>
  
       <Outlet></Outlet>
  
        {/* Additional sections would follow */}
        <footer className="py-12 border-t border-white/10">
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid md:grid-cols-4 gap-8">
          <div>
            <div className="text-2xl font-bold bg-gradient-to-r from-pink-500 to-purple-600 bg-clip-text text-transparent mb-4">
              TheAdPool
            </div>
            <p className="text-gray-400">
              Have questions or need assistance getting started? We're here to support you every step of the way.
            </p>
            <div className="mt-4 space-y-2">
              <p className="text-gray-400 flex items-center">
                <MapPin className="w-4 h-4 mr-2" />
                1209 Mountain Road Place NE, Suite H, Albuquerque, New Mexico, 87110
              </p>
              <p className="text-gray-400 flex items-center">
                <Phone className="w-4 h-4 mr-2" />
                (505) 629-0009
              </p>
              <p className="text-gray-400 flex items-center">
                <Mail className="w-4 h-4 mr-2" />
                support@theadpool.com
              </p>
            </div>
          </div>
          
          <div>
            <h4 className="text-white font-semibold mb-4">Quick Links</h4>
            <ul className="space-y-2">
              {['Features', 'Pricing', 'Testimonials', 'Legal & Privacy Policy', 'Affiliate'].map((item,i) => (
              
                <li key={item}>
                  {getFooterMenu(item, i)}    
                </li>
              ))}
            </ul>
          </div>
          
          <div>
            <h4 className="text-white font-semibold mb-4">Connect With Us</h4>
            <div className="flex space-x-4">
              {platforms.map((platform) => (
                <a
                  key={platform.name}
                  href={platform.link}
                  className="w-10 h-10 bg-white/5 rounded-full flex items-center justify-center hover:bg-white/10 transition-colors"
                >
                  {platform.icon}
                </a>
              ))}
            </div>
          </div>
        </div>
        
        <div className="mt-12 pt-8 border-t border-white/10 text-center text-gray-400">
          © 2024 TheAdPool. All rights reserved.
        </div>
      </div>
    </footer>
      </div>
    );
  }
  