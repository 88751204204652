import { ArrowRight } from "lucide-react";

export default function Affiliate() {

    return <>
        <section className="pt-32 pb-20 relative overflow-hidden">
            <div className="w-2/4 mx-auto">
            <div className="text-center mb-16">
            <h2 className="text-3xl md:text-5xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent mb-6">
            Join the Most Lucrative Advertising Affiliate Program!
            </h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
            Are you looking for a way to monetize your audience, boost your income, and become a key player in the advertising space? Look no further! The Ad Pool Affiliate Program is your opportunity to earn substantial commissions by partnering with the industry’s most innovative advertising marketplace.
            </p>
          </div>
                <div className=" text-gray-300">
                <div className="mb-3">
                        <h6 className="text-xl font-semibold text-white mt-4 mb-3">Why Partner with Ad Pool?</h6>
                        <ul>
                            <li>
                                Ad Pool is revolutionizing the way advertisers and
                                publishers connect by offering a transparent, streamlined
                                marketplace for high-quality ad placements. Our affiliate
                                program allows you to capitalize on this cutting-edge
                                platform by earning commissions for every referral, with
                                zero barriers and exceptional rewards.
                                </li>
                        </ul>
                    </div>

                    <div className="mb-3">
                        <h6 className="text-xl font-semibold text-white mt-4 mb-3">What Makes Our Program Different?</h6>
                        <ul>
                            <li>High Earning Potential: Earn a generous commission on every sale. With no cap on earnings, the more you refer, the more you earn!</li>
                            <li>Lifetime Revenue Share: Continue to receive commissions as long as your referred customers remain active users on Ad Pool.</li>
                            <li>No Minimum Payout: Get paid as soon as you earn! Unlike other programs, there’s no minimum payout threshold.</li>
                            <li>Real-Time Analytics: Track your performance with detailed analytics and reporting tools. Get insights into clicks, conversions, and payouts in real-time.</li>
                            <li>Dedicated Support: Our team is here to help you succeed. Get access to exclusive training resources, one-on-one support, and marketing materials.</li>
                        </ul>
                    </div>

                    <div className="mb-3">
                        <h6 className="text-xl font-semibold text-white mt-4 mb-3">How it Works?</h6>
                        <ul>
                            <li>Sign Up: It’s quick, easy, and completely free to join the Ad Pool Affiliate Program. Simply fill out our registration form, and you’re on your way!</li>
                            <li>Share & Promote: Use your unique affiliate link to promote Ad Pool to your audience through blogs, social media, email, or word of mouth.</li>
                            <li>Earn Commissions: Earn up to [Commission Percentage] on each referral and enjoy ongoing revenue share for the lifetime of the customer.</li>
                            <li>Get Paid: We pay out commissions monthly via your preferred payment method.</li>
                        </ul>
                    </div>

                    <div className="mb-3">
                        <h6 className="text-xl font-semibold text-white mt-4 mb-3">Who Should Join?</h6>
                        <h6 className="text-xl font-semibold text-white mt-4 mb-3">Our program is perfect for:</h6>
                        <ul>
                            <li>Digital Marketers: Leverage your audience and marketing skills to drive sales and earn commissions.</li>
                            <li>Bloggers & Influencers: Monetize your traffic by recommending a product your audience will love.</li>
                            <li>Advertising Agencies: Partner with Ad Pool to offer an additional revenue stream to your clients.</li>
                            <li>Business Consultants: Introduce clients to the Ad Pool marketplace and earn revenue for every sale.</li>  
                        </ul>
                    </div>

                    <div className="mb-3">
                        <h6 className="text-xl font-semibold text-white mt-4 mb-3">Benefits of Partnering with Ad Pool</h6>
                        <ul>
                           
                            <li>Above market commission: get paid 35% on every monthly and annual membership you sell.</li>
                            <li>Marketing Resources: Gain access to banners, videos, and promotional content tailored to your audience.</li>
                            <li>No Hidden Fees: Our program is 100% free to join. You’ll never pay any fees or charges to participate.</li>
                        </ul>
                    </div>


                    <div className="mb-3">
                        <h6 className="text-xl font-semibold text-white mt-4 mb-3">Start Earning Today!</h6>
                        <ul>
                            <li>Joining is simple and straightforward. Once approved, you’ll receive your unique affiliate link and access to your personalized dashboard, where you can track performance and payouts in real-time. It’s never been easier to earn money by promoting a top-tier advertising platform.</li>
                            <li>Don’t wait! Become an Ad Pool Affiliate Today.</li>
                            <li>
                                <a href="/register" className="text-purple-400">Join Now!</a>
                            </li>
                            <li>For questions or more information, feel free to contact our affiliate team.</li>
                        </ul>
                    </div>
                    
                </div>
            </div>
        </section>
    </>

}