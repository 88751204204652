import axios from "axios";
import { ArrowRight } from "lucide-react";
import { useEffect, useState } from "react";
import Swal from 'sweetalert2'
import PricingCard from "../components/PricingCard";
import { useSearchParams } from "react-router-dom";

export default function Register(){

  const [searchParams, setSearchParams] = useSearchParams();
  const [show, setShow] = useState("register");

  const register = async (event:any) => {

    event.preventDefault();
    const formData:any = new FormData(event.target);
    const data:any = {};
    for (let [key, value] of formData.entries()) {
      data[key] = value;
    }
    data['role']='advertiser';
    console.log(data);
    
    try{
      const res:any = await axios.post("/api/users/register", data);
      console.log(res);
      const token = res.data.token;
      Swal.fire("Registration Successful!", "", "success");
      window.location.href = "/social-media";
    }catch(err){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
      console.log(err);
    }

  }

  
  useEffect(() => {
    window.scrollTo(0, 0);

    const id = searchParams.get("id");
    if(id){
      localStorage.setItem("stripe_payment_id", id);
    }

    if(localStorage.getItem('stripe_payment_id')){
      setShow('register');
    }else{
      setShow('pricing');
    }

  }, []);

    return <>
        {
          show == "register" && <section className="pt-32 pb-20 relative overflow-hidden">
          <div className="py-8 lg:py-16 px-8 mx-auto max-w-screen-md bg-white/5 backdrop-blur-sm rounded-md">
            <h2 className="text-center text-3xl md:text-5xl font-bold text-white mb-6">Register Now</h2>
            <form action="#" className="space-y-8" onSubmit={register}>
            <div>
                <label htmlFor="username" className="block text-sm font-medium text-gray-300">Username</label>
                <input type="text" id="username" name="name" className="w-full leading-none text-gray-50 p-3 focus:outline-none focus:border-blue-700 mt-4 border-0 bg-gray-800 rounded" required />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-300">Your email</label>
                <input type="email" id="email" name="email" className="w-full leading-none text-gray-50 p-3 focus:outline-none focus:border-blue-700 mt-4 border-0 bg-gray-800 rounded" required />
              </div>
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-300">Password</label>
                <input type="password" id="password" name="password" className="w-full leading-none text-gray-50 p-3 focus:outline-none focus:border-blue-700 mt-4 border-0 bg-gray-800 rounded" required />
              </div>
          
              <button type="submit" className="px-8 py-4 bg-gradient-to-r from-pink-500 to-purple-500 rounded-xl text-white font-medium hover:shadow-lg hover:shadow-pink-500/25 transition-all duration-300 flex items-center justify-center space-x-2">
                  <span>Register</span>
                  <ArrowRight className="w-5 h-5" />
                </button>  
            </form>
          </div>
        </section>
        }
              {/* Pricing Section */}
      { show == "pricing" && <section className="py-20 pt-32 relative" id="pricing">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-5xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent mb-6">
              Simple, Transparent Pricing
            </h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
              No commissions. No hidden fees. Just valuable tools designed to help you grow your influence and income.
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
            <PricingCard
              plan="Monthly"
              price="5"
              url="https://buy.stripe.com/dR66qYc4r9pF288dQT"
              features={[
                "AI-powered campaign matching",
                "Comprehensive analytics dashboard",
                "Unlimited active campaigns",
                "0% commission on earnings",
                "Full feature access",
                "Priority support"
              ]}
            />
            <PricingCard
              plan="Annual"
              price="50"
              originalPrice="60"
              url="https://buy.stripe.com/3cs7v23xVgS7144aEE"
              features={[
                "Everything in Monthly plan",
                "2 months free",
                "Early access to new features",
                "Advanced analytics",
                "Priority campaign matching",
                "Dedicated support team"
              ]}
              isPopular={true}
            />
          </div>

          <div className="mt-12 text-center">
            <p className="text-gray-400">
              * Annual plan saves you $10/year compared to monthly billing
            </p>
          </div>
        </div>
      </section>}
    </>

}