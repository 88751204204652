import { useState } from 'react';
import { ArrowRight, Users, Target, Mail, Phone, MapPin, Star, ChevronRight, DollarSign, HandCoins, Handshake, ChartSpline, Lock } from 'lucide-react';
import PricingCard from '../components/PricingCard';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';



const StatCard = ({ number, text }: any) => (
  <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6 border border-white/10">
    <div className="text-3xl font-bold text-white mb-2">{number}</div>
    <div className="text-gray-400">{text}</div>
  </div>
);



export default function HomePage() {
  const [currentPage, setCurrentPage] = useState('home');
  const [contactForm, setContactForm] = useState({ name: '', email: '', message: '' });

  const navigate = useNavigate();

  const statistics = [
    { number: '86%', text: 'Social Media Users Want to Be Influencers' },
    { number: '12%', text: 'Currently Consider Themselves Influencers' },
    { number: '70%', text: 'Gen Z Users Turn to Social Media for Career Tips' },
    { number: '59%', text: 'Beginner Creators Make Less Than $100/year' }
  ];


  const howItWorks = [
    {
      number: "1",
      title: "Create Your Profile",
      description: "Showcase your unique style, audience, and values. Let brands see what makes you stand out."
    },
    {
      number: "2",
      title: "Join the Pool",
      description: "Collaborate with other creators to maximize your reach. Access high-value campaigns without competing alone."
    },
    {
      number: "3",
      title: "Secure Campaigns",
      description: "Participate in campaigns that perfectly match your content and audience."
    },
    {
      number: "4",
      title: "Direct Brand Communication",
      description: "Build strong relationships with brands that value your work."
    },
    {
      number: "5",
      title: "Effortless Contract Management",
      description: "Manage contracts and agreements seamlessly through our platform."
    },
    {
      number: "6",
      title: "Refine Your Strategy",
      description: "Use detailed analytics to continuously improve and optimize your performance."
    }
  ];

  const features = [
    {
      icon: <Users className="w-6 h-6" />,
      title: "Don't wait to make money",
      description: "Make money on social media no matter how small your audience by joining forces with your peers and pool your audience together."
    },
    {
      icon: <Target className="w-6 h-6" />,
      title: "Stand Out from the Crowd",
      description: "Rise above 139 million creators with 1,000-10,000 followers, gain recognition and status in your niche"
    },
    {
      icon: <Users className="w-6 h-6" />,
      title: "Join a Thriving Community",
      description: "Be part of the 207 million content creators worldwide - Connect with brands that align with your values"
    },
    {
      icon: <Target className="w-6 h-6" />,
      title: "Audience Pooling: A Game-Changer",
      description: "Combine your audience with other like-minded creators - Access campaigns previously out of reach for individual creators"
    }
  ];


  const contactUs = async (event:any) => {

    event.preventDefault();
    const formData:any = new FormData(event.target);
    const data:any = {};
    for (let [key, value] of formData.entries()) {
      data[key] = value;
    }
    
    try{
      const res:any = await axios.post("/api/contactus", data);
      console.log(res);

      Swal.fire("Your message has been sent successfully!", "", "success");
      
    }catch(err){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to send your message.",
      });
      console.log(err);
    }

  }



  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-black to-gray-900">

      {/* Hero Section */}
      <section className="pt-32 pb-20 relative overflow-hidden">
        <div className="absolute top-1/4 right-0 w-96 h-96 bg-purple-500/10 rounded-full blur-3xl"></div>
        <div className="absolute bottom-1/4 left-0 w-96 h-96 bg-pink-500/10 rounded-full blur-3xl"></div>

        <div className="max-w-7xl mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div className="space-y-6">
              <div className="inline-block px-4 py-1 bg-white/10 backdrop-blur-sm rounded-full">
                <span className="text-pink-400">Transform Your Passion Into Profit</span>
              </div>

              <h1 className="text-4xl md:text-6xl font-bold space-y-2">
                <span className="bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent block">
                  Unlock Your
                </span>
                <span className="bg-gradient-to-r from-pink-500 to-purple-500 bg-clip-text text-transparent block">
                  Social Media Potential
                </span>
              </h1>

              <p className="text-xl text-gray-300">
                You're passionate about creating content that resonates with others. It's time to transform that passion into a thriving career.
              </p>

              <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-6">
                <Link to="/register" className="px-8 py-4 bg-gradient-to-r from-pink-500 to-purple-500 rounded-xl text-white font-medium hover:shadow-lg hover:shadow-pink-500/25 transition-all duration-300 flex items-center justify-center space-x-2">
                  <span>Get Started Now</span>
                  <ArrowRight className="w-5 h-5" />
                </Link>
                <button className="px-8 py-4 bg-white/10 rounded-xl text-white font-medium hover:bg-white/20 transition-all duration-300">
                  Learn More
                </button>
              </div>

              <div className="grid grid-cols-2 gap-4 pt-8">
                {statistics.map((stat, index) => (
                  <StatCard key={index} number={stat.number} text={stat.text} />
                ))}
              </div>
            </div>

            <div className="relative hidden md:block">
              <div className="relative h-[600px] w-full">
                <div className="absolute top-0 right-0 w-4/5 h-4/5 bg-gradient-to-br from-purple-600/20 to-pink-500/20 backdrop-blur-xl rounded-2xl border border-white/10 shadow-2xl transform rotate-6"></div>
                <div className="absolute top-10 right-10 w-4/5 h-4/5 bg-gradient-to-br from-pink-500/20 to-purple-600/20 backdrop-blur-xl rounded-2xl border border-white/10 shadow-2xl transform -rotate-3"></div>
                <div className="absolute top-20 right-20 w-4/5 h-4/5 bg-gradient-to-br from-purple-600/20 to-pink-500/20 backdrop-blur-xl rounded-2xl border border-white/10 shadow-2xl">
                  <img src="/images/dashboard.png" alt="Dashboard Preview" className="w-full h-full object-cover rounded-2xl opacity-90" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section className="py-20 relative" id="pricing">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-5xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent mb-6">
              Simple, Transparent Pricing
            </h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
              No commissions. No hidden fees. Just valuable tools designed to help you grow your influence and income.
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
            <PricingCard
              plan="Monthly"
              price="5"
              url="https://buy.stripe.com/dR66qYc4r9pF288dQT"
              features={[
                "AI-powered campaign matching",
                "Comprehensive analytics dashboard",
                "Unlimited active campaigns",
                "0% commission on earnings",
                "Full feature access",
                "Priority support"
              ]}
            />
            <PricingCard
              plan="Annual"
              price="50"
              originalPrice="60"
              url="https://buy.stripe.com/3cs7v23xVgS7144aEE"
              features={[
                "Everything in Monthly plan",
                "2 months free",
                "Early access to new features",
                "Advanced analytics",
                "Priority campaign matching",
                "Dedicated support team"
              ]}
              isPopular={true}
            />
          </div>

          <div className="mt-12 text-center">
            <p className="text-gray-400">
              * Annual plan saves you $10/year compared to monthly billing
            </p>
          </div>
        </div>
      </section>


      <div className="min-h-screen bg-gradient-to-br from-gray-900 via-black to-gray-900">

        {/* How It Works Section */}
        <section className="py-20 relative overflow-hidden" id='how-it-works'>
          {/* <div className="absolute top-1/4 right-0 w-96 h-96 bg-purple-500/10 rounded-full blur-3xl"></div>
          <div className="absolute bottom-1/4 left-0 w-96 h-96 bg-pink-500/10 rounded-full blur-3xl"></div> */}

          <div className="max-w-7xl mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-3xl md:text-5xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent mb-6">
                How TheAdPool Works: A Seamless Experience
              </h2>
              <p className="text-gray-400 max-w-2xl mx-auto">
                Experience a seamless journey from connection to conversion with our intuitive platform designed for both social media influencers and advertisers.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {howItWorks.map((step, index) => (
                <div key={index} className="group relative bg-white/5 backdrop-blur-sm rounded-2xl p-6 hover:bg-white/10 transition-all duration-300 border border-white/10">
                  <div className="absolute -top-4 -left-4 w-12 h-12 bg-gradient-to-r from-pink-500 to-purple-500 rounded-lg flex items-center justify-center text-white font-bold">
                    {step.number}
                  </div>
                  <h3 className="text-xl font-semibold text-white mt-4 mb-3">{step.title}</h3>
                  <p className="text-gray-400">{step.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Features Section */}
      <section className="py-20 relative" id='features'>
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-5xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent mb-6">
              Turn Your Creativity Into Income
            </h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
              Join creators who've transformed their passion into a thriving business
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {features.map((feature, index) => (
              <div key={index} className="group bg-white/5 backdrop-blur-sm rounded-2xl p-8 hover:bg-white/10 transition-all duration-300 border border-white/10">
                <div className="w-12 h-12 bg-gradient-to-r from-pink-500 to-purple-500 rounded-xl flex items-center justify-center text-white mb-6">
                  {feature.icon}
                </div>
                <h3 className="text-xl font-semibold text-white mb-4">{feature.title}</h3>
                <p className="text-gray-400">{feature.description}</p>
              </div>
            ))}
          </div>

          <div className="mt-16 bg-gradient-to-r from-pink-500/20 to-purple-500/20 rounded-2xl p-8 backdrop-blur-sm border border-white/10">
            <div className="grid md:grid-cols-2 gap-8 items-center">
              <div>
                <h3 className="text-2xl font-bold text-white mb-4">Ready to Start Growing?</h3>
                <p className="text-gray-400 mb-6">Join thousands of creators who are already succeeding with TheAdPool</p>
                <button onClick={() => navigate('/register')} className="px-8 py-4 bg-gradient-to-r from-pink-500 to-purple-500 rounded-xl text-white font-medium hover:shadow-lg hover:shadow-pink-500/25 transition-all duration-300 transform hover:-translate-y-0.5 flex items-center space-x-2">
                  <span>Get Started Now</span>
                  <ArrowRight className="w-5 h-5" />
                </button>
              </div>
              <div className="flex justify-end">
                <div className="grid grid-cols-2 gap-4">
                  <div className="text-center">
                    <div className="text-3xl font-bold text-white mb-2">10k+</div>
                    <div className="text-gray-400">Active Creators</div>
                  </div>
                  <div className="text-center">
                    <div className="text-3xl font-bold text-white mb-2">95%</div>
                    <div className="text-gray-400">Success Rate</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-20 relative" id='testimonials'>
          <div className="max-w-7xl mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-3xl md:text-5xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent mb-6">
                Success Stories from Our Community
              </h2>
              <p className="text-gray-400 max-w-2xl mx-auto">
                Hear from our satisfied influencers and advertisers who have transformed their social media presence with TheAdPool.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="group bg-white/5 backdrop-blur-sm rounded-2xl p-8 hover:bg-white/10 transition-all duration-300 border border-white/10">
                <h3 className="text-xl font-semibold text-white mb-4">"TheAdPool transformed my passion into a career. By pooling my audience with others, I’ve expanded my reach and landed deals I never thought possible."</h3>
                <p className="text-gray-400">Alex Reynolds, Lifestyle Influencer</p>
              </div>
              <div className="group bg-white/5 backdrop-blur-sm rounded-2xl p-8 hover:bg-white/10 transition-all duration-300 border border-white/10">
                <h3 className="text-xl font-semibold text-white mb-4">"TheAdPool’s collaborative approach allowed us to connect with influencers who truly resonate with our brand. It’s efficient and effective.."</h3>
                <p className="text-gray-400">Melissa Thompson, Marketing Director at EcoTech</p>
              </div>
            </div>

          </div>
        </section>

      {/* Analytics Dashboard Preview */}
      <section className="py-20 relative overflow-hidden">
          <div className="max-w-7xl mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-3xl md:text-5xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent mb-6">
                Perfect Dashbaord
              </h2>
              <p className="text-gray-400 max-w-2xl mx-auto">
                Discover Your Perfect Dashboard In Our Website
              </p>
            </div>


            <img src="/images/dashboard.png" alt="" className='w-full' />
          </div>
        </section>

        {/* Contact us section */}
        <section className="mb-20" id='contact'>
          <div className="py-8 lg:py-16 px-8 mx-auto max-w-screen-md bg-white/5 backdrop-blur-sm rounded-md">
            <h2 className="text-center text-3xl md:text-5xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent mb-6">Contact Us</h2>
            <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">Got a technical issue? Want to send feedback about a beta feature? Need details about our Business plan? Let us know.</p>
            <form action="#" className="space-y-8" onSubmit={contactUs}>
              <div>
                <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-300">Name</label>
                <input type="text" id="name" name="name" className="w-full leading-none text-gray-50 p-3 focus:outline-none focus:border-blue-700 mt-4 border-0 bg-gray-800 rounded" required />
              </div>
              <div>
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-300">Your email</label>
                <input type="email" id="email" name="email" className="w-full leading-none text-gray-50 p-3 focus:outline-none focus:border-blue-700 mt-4 border-0 bg-gray-800 rounded" required />
              </div>
              
              <div className="sm:col-span-2">
                <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-300">Your message</label>
                <textarea id="message" name='message' rows={6} className="w-full leading-none text-gray-50 p-3 focus:outline-none focus:border-blue-700 mt-4 border-0 bg-gray-800 rounded" ></textarea>
              </div>
              <button type='submit' className="px-8 py-4 bg-gradient-to-r from-pink-500 to-purple-500 rounded-xl text-white font-medium hover:shadow-lg hover:shadow-pink-500/25 transition-all duration-300 flex items-center justify-center space-x-2">
                  <span>Get Started Now</span>
                  <ArrowRight className="w-5 h-5" />
                </button>  
            </form>
          </div>
        </section>


      </div>

    </div>
  );
}
