import { useState } from 'react';
import { ArrowRight, Users, Target, Mail, Phone, MapPin, Star, ChevronRight } from 'lucide-react';
import { Outlet, Link } from "react-router-dom";


export default function NotFoundPage() {


    return (
        <main className="grid min-h-full place-items-center  px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-white">404</p>
          <h1 className="text-3xl md:text-5xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent mb-6">Page not found</h1>
          <p className="mt-6 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">Sorry, we couldn’t find the page you’re looking for.</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a href="/" className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Go back home</a>
            <a href="/#contact" className="text-sm font-semibold text-white">Contact support <span aria-hidden="true">&rarr;</span></a>
          </div>
        </div>
      </main>
    );
  }
  