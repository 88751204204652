import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFoundPage from './pages/NotFoundPage';
import MainLayout from './components/MainLayout';
import HomePage from './pages/HomePage';
import Login from './pages/Login';
import Register from './pages/Register';
import ChoosePlan from './pages/ChoosePlan';
import SocialMedia from './pages/SocialMedia';
import SocialPool from './pages/SocialPool';
import Terms from './pages/Terms';
import Affiliate from './pages/Affiliate';
import Dashbaord from './pages/Dashboard';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<HomePage />} />
          <Route path="/choose-plan" element={<ChoosePlan />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/social-media" element={<SocialMedia />} />
          <Route path="/social-pool" element={<SocialPool />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/affiliate" element={<Affiliate />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
        <Route path="/dashboard" element={<Dashbaord />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
