import axios from "axios";
import { ArrowRight, Facebook, Instagram, Twitter, TypeOutline, Youtube } from "lucide-react";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";

export default function SocialMedia() {


  const [form, setForm] = useState({
    username: '',
    followers: '',
    engagement: '',
    url: ''
  });

  function fetchSocialData(social: string): void {
    
    const socialData: any = {
      facebook: {
        username: 'John Doe',
        followers: '5000',
        engagement: '4.5%',
        url: 'https://www.facebook.com/john.doe.123'
      },
      instagram: {
        username: 'insta_user',
        followers: '12345',
        engagement: '12000',
        url: '7.8%'
      },
      twitter: {
        username: 'twitter_user',
        followers: '8000',
        engagement: '6.1%',
        url: 'https://twitter.com/98765'
      },
      youtube: {
        username: 'YouTube Channel',
        followers: '45000',
        engagement: '5.2%',
        url: 'https://www.youtube.com/channel/UC123456'
      },
      tiktok: {
        username: 'tiktok_user',
        followers: '20000',
        engagement: '9.3%',
        url: 'https://www.tiktok.com/@tik_tok_789'
      },
    };

    setForm(socialData[social]);

  }


  const submitSocial = async (event:any) => {

    event.preventDefault();
    const formData:any = new FormData(event.target);
    const data:any = {};
    for (let [key, value] of formData.entries()) {
      data[key] = value;
    }

    let er = data['engagement_rate'].match(/\d/g);
    er = er.join("");
    data['engagementRate'] = er;
    
    try{
      const token = localStorage.getItem("token");
      const res:any = await axios.post("/api/socialmedia", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(res);

      Swal.fire("Social media account saved successfully!", "", "success").then(() => {
        window.location.href = '/social-pool'; 
      });
      
    }catch(err){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to save social media account.",
      });
      console.log(err);
    }

  }

  const fetchSocialMedia = async () => {

    try{
      const token = localStorage.getItem("token");
      const res = await axios.get("/api/socialmedia", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const apiData = res.data;
      setForm({
        username: apiData.username,
        followers: apiData.followers,
        engagement: apiData.engagementRate,
        url: apiData.url
      });
    }catch(err){
      console.log(err);
    }

  }


  useEffect(() => {
    fetchSocialMedia();
  }, []);


  return <>

    {/* Hero Section */}
    <section className="pt-32 pb-20 relative overflow-hidden">
      <div className="absolute top-1/4 right-0 w-96 h-96 bg-purple-500/10 rounded-full blur-3xl"></div>
      <div className="absolute bottom-1/4 left-0 w-96 h-96 bg-pink-500/10 rounded-full blur-3xl"></div>

      <div className="max-w-7xl mx-auto px-4">
        <div className="grid md:grid-cols-1 gap-12 items-center text-center">


          <h1 className="text-4xl md:text-6xl font-bold space-y-2">
            <span className="bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent block">
              Unlock Your
            </span>
            <span className="bg-gradient-to-r from-pink-500 to-purple-500 bg-clip-text text-transparent block">
              Social Media Potential
            </span>
          </h1>

          <p className="text-xl text-gray-300">
            You're passionate about creating content that resonates with others. It's time to transform that passion into a thriving career.
          </p>

          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-6 justify-center">
            <button className="px-8 py-4 bg-gradient-to-r from-pink-500 to-purple-500 rounded-xl text-white font-medium hover:shadow-lg hover:shadow-pink-500/25 transition-all duration-300 flex items-center justify-center space-x-2">
              <span>Get Started Now</span>
              <ArrowRight className="w-5 h-5" />
            </button>
            <button className="px-8 py-4 bg-white/10 rounded-xl text-white font-medium hover:bg-white/20 transition-all duration-300">
              Learn More
            </button>
          </div>


        </div>
      </div>
    </section>

    <section className="pt-32 pb-20 relative overflow-hidden">
      <div className="py-8 lg:py-16 px-8 bg-white/5 backdrop-blur-sm rounded-md">
        <div className="container mx-auto">
          <h2 className="text-center text-3xl md:text-5xl font-bold text-white mb-6">Ready to Maximize Your Influence?</h2>
          <p className="text-gray-400 text-center">
            Have questions or eager to revolutionize your social media monetization? We're here to guide you!
          </p>
          <div className="mb-3">
            <button className="p-2 bg-purple-500 rounded mr-2" onClick={() => fetchSocialData('facebook')}>
            {<Facebook className="w-5 h-5 text-white"/>}
            </button>

            <button id="fetch-instagram" className="p-2 bg-purple-500 rounded mr-2" onClick={() => fetchSocialData('instagram')}>
              {<Instagram className="w-5 h-5 text-white"/>}
            </button>

            <button id="fetch-twitter" className="p-2 bg-purple-500 rounded mr-2" onClick={() => fetchSocialData('twitter')}>
            {<Twitter className="w-5 h-5 text-white"/>}
            </button>

            <button id="fetch-youtube" className="p-2 bg-purple-500 rounded mr-2" onClick={() => fetchSocialData('youtube')}>
            {<Youtube className="w-5 h-5 text-white"/>}
            </button>
            <button id="fetch-tiktok" className="p-2 bg-purple-500 rounded mr-2" onClick={() => fetchSocialData('tiktok')}>
            {<TypeOutline className="w-5 h-5 text-white"/>}
                    </button>
          </div>
          <form onSubmit={submitSocial} action="#" className="space-y-8">
            
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="username" className="block text-sm font-medium text-gray-300">Username</label>
                <input value={form.username} onChange={(e) => setForm({...form, username: e.target.value})} type="text" name="username" id="username" className="w-full leading-none text-gray-50 p-3 focus:outline-none focus:border-blue-700 mt-4 border-0 bg-gray-800 rounded" required />
              </div>
              <div>
                <label htmlFor="followers" className="block text-sm font-medium text-gray-300">Followers</label>
                <input value={form.followers} onChange={(e) => setForm({...form, username: e.target.value})} type="text" name="followers" id="followers" className="w-full leading-none text-gray-50 p-3 focus:outline-none focus:border-blue-700 mt-4 border-0 bg-gray-800 rounded" required />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="engagement_rate" className="block text-sm font-medium text-gray-300">Engagement Rate (%)</label>
                <input value={form.engagement} onChange={(e) => setForm({...form, username: e.target.value})} type="text" name="engagement_rate" id="engagement_rate" className="w-full leading-none text-gray-50 p-3 focus:outline-none focus:border-blue-700 mt-4 border-0 bg-gray-800 rounded" required />
              </div>
              <div>
                <label htmlFor="url" className="block text-sm font-medium text-gray-300">URL</label>
                <input value={form.url} onChange={(e) => setForm({...form, username: e.target.value})} type="text" id="url" name="url" className="w-full leading-none text-gray-50 p-3 focus:outline-none focus:border-blue-700 mt-4 border-0 bg-gray-800 rounded" required />
              </div>
            </div>

            <button className="px-8 py-4 bg-gradient-to-r from-pink-500 to-purple-500 rounded-xl text-white font-medium hover:shadow-lg hover:shadow-pink-500/25 transition-all duration-300 flex items-center justify-center space-x-2">
              <span>Get Started Now</span>
              <ArrowRight className="w-5 h-5" />
            </button>
          </form>
        </div>

      </div>
    </section>

  </>

}