import axios from "axios";
import { ArrowRight } from "lucide-react";
import { useEffect } from "react";
import Swal from "sweetalert2";

export default function Login(){


  const login = async (event:any) => {

    event.preventDefault();
    const formData:any = new FormData(event.target);
    const data:any = {};
    for (let [key, value] of formData.entries()) {
      data[key] = value;
    }
    
    try{
      const res:any = await axios.post("/api/users/login", data);
      console.log(res);
      const token = res.data.token;
      localStorage.setItem("token", token);
      Swal.fire("Login Successful!", "", "success").then(() => {
        window.location.href = "/social-media";
      });
      
    }catch(err){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Wrong Credentials!",
      });
      console.log(err);
    }

  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    return <>
    <section className="pt-32 pb-20 relative overflow-hidden">
          <div className="py-8 lg:py-16 px-8 mx-auto max-w-screen-md bg-white/5 backdrop-blur-sm rounded-md">
            <h2 className="text-center text-3xl md:text-5xl font-bold text-white mb-6">Login Now</h2>
            <form onSubmit={login} action="#" className="space-y-8">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-300">Your email</label>
                <input type="email" id="email" name="email" className="w-full leading-none text-gray-50 p-3 focus:outline-none focus:border-blue-700 mt-4 border-0 bg-gray-800 rounded" required />
              </div>
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-300">Password</label>
                <input type="password" id="password" name="password" className="w-full leading-none text-gray-50 p-3 focus:outline-none focus:border-blue-700 mt-4 border-0 bg-gray-800 rounded" required />
              </div>
          
              <button className="px-8 py-4 bg-gradient-to-r from-pink-500 to-purple-500 rounded-xl text-white font-medium hover:shadow-lg hover:shadow-pink-500/25 transition-all duration-300 flex items-center justify-center space-x-2">
                  <span>Login</span>
                  <ArrowRight className="w-5 h-5" />
                </button>  
            </form>
          </div>
        </section>
    </>

}