import { ArrowRight, ChartSpline, CircleCheck, DollarSign, Target, Users } from "lucide-react";
import Chart from 'chart.js/auto';
import { CategoryScale } from "chart.js";
import LineChart from "../components/LineChart";
import { Data } from "../utils/Data";
import { useState } from "react";
import PieChart from "../components/PieChart";
Chart.register(CategoryScale);


export default function Dashbaord() {

    const features = [
        {
            icon: <DollarSign className="w-6 h-6" />,
            title: "Total Earnings",
            description: "$0"
        },
        {
            icon: <ChartSpline className="w-6 h-6" />,
            title: "Recent Earnings (30 days)",
            description: "$0"
        },
        {
            icon: <Users className="w-6 h-6" />,
            title: "Active Campaigns",
            description: "0"
        },
        {
            icon: <CircleCheck className="w-6 h-6" />,
            title: "Completed Campaigns",
            description: "0"
        }
    ];
    
    
    const [chartData, setChartData] = useState({
        labels: Data.map((data) => data.year), 
        datasets: [
          {
            label: "Earnings Over Time",
            data: Data.map((data) => data.userGain),
        
          }
        ]
      });

    const [chartshow, setChartShow] = useState('line');

    return <>
        <div className="min-h-screen bg-gradient-to-br from-gray-900 via-black to-gray-900">
            <section className="pt-10 pb-20 relative overflow-hidden">
                <div className="w-2/4 mx-auto">
                    <div className="text-center mb-16">
                        <h2 className="text-3xl md:text-5xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent mb-6">
                            Welcome, Creator!
                        </h2>
                        <div className="flex justify-center mb-3">
                            <button onClick={() => setChartShow("line")} id="overviewBtn" className="p-2 bg-gray-50 mr-2 text-gray-900 rounded">
                                Overview
                            </button>
                            <button onClick={() => setChartShow("pie")} id="audienceBtn" className="p-2 bg-gray-50 mr-2 text-gray-900 rounded">Audience</button>
                        </div>
                    </div>
                </div>

                {/* Features Section */}
                {
                    chartshow === "line" && <section className="relative" id='features'>
                        <div className="w-4/6 mx-auto px-4">

                            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                                {features.map((feature, index) => (
                                    <div key={index} className="group bg-white/5 backdrop-blur-sm rounded-2xl p-8 hover:bg-white/10 transition-all duration-300 border border-white/10">
                                        <div className="w-12 h-12 bg-gradient-to-r from-pink-500 to-purple-500 rounded-xl flex items-center justify-center text-white mb-6">
                                            {feature.icon}
                                        </div>
                                        <h3 className="text-xl font-semibold text-white mb-4">{feature.title}</h3>
                                        <p className="text-gray-400">{feature.description}</p>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </section>
                }


                {/* Line Chart */}
                {
                    chartshow === "line" && <div className="continer pt-10">
                    <div className="w-4/6 mx-auto p-10 bg-white rounded">
                        <LineChart chartData={chartData} />
                    </div>
                </div>
                }
                

                {/* Line Chart */}
                {
                    chartshow === "pie" && <div className="continer pt-10">
                        <div className="w-4/6 mx-auto">
                            <div className="grid grid-cols-2 gap-4">
                                <div className="bg-white rounded  p-10">
                                    <PieChart chartData={chartData} />
                                </div>
                                <div className="bg-white rounded  p-10">
                                    <PieChart chartData={chartData} />
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </section>
        </div>
    </>

}