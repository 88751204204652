import { ArrowRight } from "lucide-react";

export default function Terms() {

    return <>
        <section className="pt-32 pb-20 relative overflow-hidden">
            <div className="w-2/4 mx-auto">
            <div className="text-center mb-16">
            <h2 className="text-3xl md:text-5xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent mb-6">
            The AdPool Terms of Service
            </h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
            Last Updated: 1/09/2024
            </p>
          </div>
                <div className=" text-gray-300">

                    <p>Welcome to The AdPool! By accessing or using our website and services, you agree
                        to comply with and be bound by these Terms of Service (“Terms”). These Terms
                        constitute a legally binding agreement between you and The AdPool, LLC, a
                        limited liability company registered in the State of New Mexico, United States.
                        If you do not agree with any part of these Terms, please refrain from using our
                        website or services.</p>

                    <h6 className="text-xl font-semibold text-white mt-4 mb-3">1. Acceptance of Terms</h6>

                    By using our website or services, you acknowledge that you have read, understood,
                    and agreed to be bound by these Terms, along with our Privacy Policy and any other
                    legal notices or agreements posted on The AdPool website. These Terms apply to all
                    users, including affiliates, customers, and influencers.

                    <h6 className="text-xl font-semibold text-white mt-4 mb-3">2. Eligibility</h6>

                    To use our services, you must be at least 18 years old and capable of forming
                    legally binding contracts under applicable law. By creating an account or using The
                    AdPool, you represent and warrant that you meet these eligibility requirements.

                    <h6 className="text-xl font-semibold text-white mt-4 mb-3">3. Account Registration and Security</h6>
                    <ul>
                        <li>Account Creation: To access certain features of our services, you must
                            create an account. You agree to provide accurate and complete information
                            during registration and update it as necessary.</li>
                        <li>Account Responsibility: You are responsible for maintaining the
                            confidentiality of your account information, including your password. You
                            are also responsible for all activities that occur under your account.</li>
                        <li>Termination: The AdPool reserves the right to suspend or terminate your
                            account at any time, with or without notice, for any reason, including
                            violation of these Terms.</li>
                    </ul>

                    <h6 className="text-xl font-semibold text-white mt-4 mb-3">4. Use of The AdPool Services</h6>
                    <ul>

                        <li>Prohibited Activities: You agree not to engage in activities that violate
                            any laws or regulations or infringe on the rights of others. Prohibited
                            activities include, but are not limited to, spamming, hacking, spreading
                            false information, harassment, or uploading harmful content.</li>
                        <li>Content Ownership: All content and materials on The AdPool, including text,
                            graphics, logos, images, and software, are the property of The AdPool or its
                            licensors and are protected by intellectual property laws. You may not
                            reproduce, distribute, or create derivative works without permission.</li>
                        <li>User Content: By posting or uploading content to The AdPool, you grant us a
                            worldwide, royalty-free, perpetual license to use, reproduce, modify, and
                            display such content in connection with the provision of our services.</li>
                    </ul>

                    <h6 className="text-xl font-semibold text-white mt-4 mb-3">5. Payments and Refund Policy</h6>

                    <ul>
                        <li>Subscription Fees: Some features of The AdPool may require payment or
                            subscription fees. All fees are stated in U.S. dollars and are payable in
                            advance. Payment methods include credit card, debit card, or other payment
                            options provided by The AdPool.</li>
                        <li>No Refunds: All payments made to The AdPool are non-refundable. By
                            subscribing to our services, you acknowledge that there are no refunds or
                            credits for unused services, unless required by applicable law.</li>
                    </ul>

                    <h6 className="text-xl font-semibold text-white mt-4 mb-3"> 6. Affiliate Program Terms</h6>

                    <li>Commission Rate: Affiliates are eligible to receive a 35% commission on all
                        verified sign-ups referred through their unique affiliate links.</li>
                    <li>Prohibited Practices: Affiliates must not engage in deceptive marketing
                        practices or misrepresent The AdPool. Violations of these terms may result in
                        the suspension of the affiliate account and forfeiture of commissions.</li>
                    <li>Payouts: Commission payouts will be made in accordance with our affiliate payout
                        schedule. The AdPool reserves the right to modify or terminate the affiliate
                        program at any time.</li>

                    <h6 className="text-xl font-semibold text-white mt-4 mb-3">7. Intellectual Property Rights</h6>

                    <li>Ownership: The AdPool and its licensors retain all rights, titles, and interests
                        in and to all content, trademarks, and materials provided on our website and
                        services.</li>
                    <li>License: Subject to these Terms, we grant you a limited, non-exclusive,
                        non-transferable, revocable license to access and use our services for personal,
                        non-commercial purposes.</li>

                    <h6 className="text-xl font-semibold text-white mt-4 mb-3"> 8. Limitation of Liability</h6>

                    <li>Disclaimer of Warranties: The AdPool services are provided “as is” and “as
                        available” without any warranties of any kind, either express or implied.</li>
                    <li>Limitation of Liability: To the fullest extent permitted by law, The AdPool will
                        not be liable for any indirect, incidental, or consequential damages, including
                        lost profits or business opportunities, arising from your use of our services.</li>

                    <h6 className="text-xl font-semibold text-white mt-4 mb-3">9. Dispute Resolution</h6>

                    <li>Governing Law: These Terms are governed by and construed in accordance with the
                        laws of the State of New Mexico, United States, without regard to its conflict
                        of law principles.</li>
                    <li>Binding Arbitration: Any disputes arising out of or relating to these Terms will
                        be resolved through binding arbitration in Albuquerque, New Mexico. The
                        arbitration will be conducted under the rules of the American Arbitration
                        Association (AAA).</li>

                    <h6 className="text-xl font-semibold text-white mt-4 mb-3">10. Modifications to Terms</h6>

                    The AdPool reserves the right to modify these Terms at any time. Any changes will be
                    effective upon posting on our website. Continued use of the site or services after
                    such changes constitutes your acceptance of the revised Terms.

                    <h6 className="text-xl font-semibold text-white mt-4 mb-3">11. Governing Law and Jurisdiction</h6>

                    These Terms shall be governed by and construed in accordance with the laws of the
                    State of New Mexico. You agree to submit to the exclusive jurisdiction of the courts
                    located in Albuquerque, New Mexico, for any disputes arising out of or relating to
                    these Terms.

                    <h6 className="text-xl font-semibold text-white mt-4 mb-3">12. Indemnification</h6>

                    You agree to indemnify and hold harmless The AdPool, its affiliates, and their
                    respective officers, directors, employees, and agents from and against any claims,
                    damages, losses, and expenses, including legal fees, arising out of your use of our
                    services or your breach of these Terms.

                    <h6 className="text-xl font-semibold text-white mt-4 mb-3">13. Contact Information</h6>

                    For questions or concerns regarding these Terms, please contact us at:

                    <li>Email: support@theadpool.com</li>
                    <li>Phone: (505) 629-0009</li>
                    <li>Address: The AdPool, LLC, 1209 Mountain Road Place NE, Suite H, Albuquerque, New
                        Mexico, 87110</li>

                    By using The AdPool, you agree to these Terms of Service. Thank you for being part
                    of our community!The AdPool Terms of Service

                </div>
            </div>
        </section>
    </>

}